import React from 'react';

import Loading from './components/Loading';
import isUserAuthenticated from "./services/auth/isUserAuthenticated";

const AuthenticatedApp = React.lazy(() => import('./components/App'));
const Landing = React.lazy(() => import('./components/Landing'));


function App() {

  return (
    <React.Suspense fallback={<Loading />}>
      {isUserAuthenticated() ? <AuthenticatedApp /> : <Landing />}
    </React.Suspense>
  );
}

export default App;

import React from 'react';

import '../styles/Loading.css';

function Loading(props) {
    return (
        <span className="loading">Loading content...</span>
    )
}

export default Loading;
